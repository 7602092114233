import React from "react";
import styles from "../styles/Maintenance.module.css";

export default function Maintenance() {

  return (
    <main id={styles["maintenance-main"]}>
      <section>
        <section>making something really cool - check back later</section>
        <a href="https://den.makuwro.com">discord</a>
      </section>
    </main>
  );

}